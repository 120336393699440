import { CustomerType } from "./CustomerType.js";

export const minOrderHour = 10;
export const minOrderMinute = 0;

export const maxOrderHour = 17;
export const maxOrderMinute = 30;

export const expressOrderCutoffHour = 16;

export const orderCutoffHours: Record<CustomerType, number> = {
	[CustomerType.B2c]: 7,
	[CustomerType.B2b]: 13,
};

export const orderTimeStepInSeconds = 15 * 60;
